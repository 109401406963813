import { useDispatch, useSelector } from 'react-redux';
// * Selectors
import { selectAuthFormTypes } from '../../../store/auth/auth.selector';
// * Action
import { clearAuthData, clearAuthErrors, setAuthFormTypeActive } from '../../../store/auth/auth.action';
// * Styles
import './auth-types.styles.scss';

export const AUTH_FORM_TYPES = {
    CREDENTIALS: "credentials",
    NUMBER: 'number'
}

const AuthTypes = ({formActiveType}) => {
    const formTypes = useSelector(selectAuthFormTypes);
    const dispatch = useDispatch();

    const handleTypeClick = (type) => {
        dispatch(setAuthFormTypeActive(type));
        dispatch(clearAuthData());
        dispatch(clearAuthErrors());
    }

    return (
        <div className="auth-types">
            <ul className="auth-type-items">
                {formTypes.map((type) => (
                    <li key={type} className={`auth-type-item text-capitalize ${type === formActiveType && 'active'}`} onClick={() => handleTypeClick(type)}>
                        {type}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default AuthTypes;