import { useSelector } from "react-redux";
import "./add-announcement.style.scss";
import AddAnnouncementCategory, {
  AnnouncementCategoryTypes,
} from "./steps/add-announcement-category.component";
import { selectAddAnnouncementCurrentStep } from "../../../store/add-announcement/add-announcement.selector";
import AddAnnouncementAddress from "./steps/address/add-announcement-address.component";
import AddAnnouncementOption from "./steps/option/add-announcement-option.component";
import AddAnnouncementSelectOption from "./steps/select-option/add-announcement-select-option.component";
import AddAnnouncementTitle from "./steps/title/add-announcement-title.component";
import AddAnnouncementDescription from "./steps/description/add-announcement-description.component";

export const ADD_ANNOUNCEMENT_STEPS = {
  MAIN_CATEGORY: {
    NAME: "MAIN_CATEGORY",
    BACK_STEP: null,
    NEXT_STEP: "CATEGORY",
    COMPONENT: <AddAnnouncementCategory />,
  },
  CATEGORY: {
    NAME: "CATEGORY",
    BACK_STEP: "MAIN_CATEGORY",
    NEXT_STEP: "SUB_CATEGORY",
    COMPONENT: (
      <AddAnnouncementCategory
        title="Select your category ..."
        type={AnnouncementCategoryTypes.CATEGORY}
      />
    ),
  },
  SUB_CATEGORY: {
    NAME: "SUB_CATEGORY",
    BACK_STEP: "CATEGORY",
    NEXT_STEP: "ADDRESS",
    COMPONENT: (
      <AddAnnouncementCategory
        title="Select your sub category ..."
        type={AnnouncementCategoryTypes.SUB_CATEGORY}
      />
    ),
  },
  ADDRESS: {
    NAME: "ADDRESS",
    BACK_STEP: "SUB_CATEGORY",
    NEXT_STEP: "ADD_ANNOUNCEMENT_OPTION",
    COMPONENT: <AddAnnouncementAddress />,
  },
  ADD_ANNOUNCEMENT_OPTION: {
    NAME: "ADD_ANNOUNCEMENT_OPTION",
    BACK_STEP: "ADDRESS",
    NEXT_STEP: "ADD_ANNOUNCEMENT_SELECT_OPTION",
    COMPONENT: <AddAnnouncementOption />,
  },
  ADD_ANNOUNCEMENT_SELECT_OPTION: {
    NAME: "ADD_ANNOUNCEMENT_SELECT_OPTION",
    BACK_STEP: "ADD_ANNOUNCEMENT_OPTION",
    NEXT_STEP: "ADD_ANNOUNCEMENT_TITLE",
    COMPONENT: <AddAnnouncementSelectOption />,
  },
  ADD_ANNOUNCEMENT_TITLE: {
    NAME: "ADD_ANNOUNCEMENT_TITLE",
    BACK_STEP: "ADD_ANNOUNCEMENT_SELECT_OPTION",
    NEXT_STEP: "ADD_ANNOUNCEMENT_DESCRIPTION",
    COMPONENT: <AddAnnouncementTitle />,
  },
  ADD_ANNOUNCEMENT_DESCRIPTION: {
    NAME: "ADD_ANNOUNCEMENT_DESCRIPTION",
    BACK_STEP: "ADD_ANNOUNCEMENT_TITLE",
    NEXT_STEP: null,
    COMPONENT: <AddAnnouncementDescription />,
  },
};

export const getProgress = (name) => {
  let steps = [];
  for (const key in ADD_ANNOUNCEMENT_STEPS) {
    steps.push(key);
  }
  let index = steps.findIndex((std) => std === name) + 1;
  let count = steps.length;
  let progress = (index / count) * 100;
  return progress;
};

const AddAnnouncement = () => {
  const currentStep = useSelector(selectAddAnnouncementCurrentStep);
  return <div className="add-announcement">{currentStep.COMPONENT}</div>;
};

export default AddAnnouncement;
