import UsaFlag from '../../assets/images/flags/usa.svg';

export const LocalesData = [
    {
        "id": 1,
        "name": "English",
        "code": "en",
        "flag": UsaFlag,
        "rtl": 0
    },
]