import { Countries } from "../../fake-db/countries/countries";
import { COUNTRY_ACTION_TYPES } from "./country.types";

const COUNTRY_INITIAL_STATE = {
  countries: [],
  isLoading: false,
  error: null
};

export const countryReducer = (state = COUNTRY_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case COUNTRY_ACTION_TYPES.SET_COUNTRIES:
      return { ...state, countries: payload };
    case COUNTRY_ACTION_TYPES.GET_COUNTRIES_START:
      return { ...state, isLoading: true };
    case COUNTRY_ACTION_TYPES.GET_COUNTRIES_SUCCESS:
      return { ...state, isLoading: false, countries: payload };
    case COUNTRY_ACTION_TYPES.GET_COUNTRIES_FAILED:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
};
