import CreditIcon from "../../assets/images/payment/credit-card-icon.png";

export const creditCardsData = [
  {
    icon: CreditIcon,
    type: "credit",
    label: "Credit Card",
    name: "Andres Nora",
    number: "**** **** **** 2837",
    expiry: {
      month: "06",
      year: "25",
    },
  },
  {
    icon: CreditIcon,
    type: "visa",
    label: "Visa Card",
    name: "Andres Nora",
    number: "**** **** **** 2837",
    expiry: {
      month: "06",
      year: "25",
    },
  },
  {
    icon: CreditIcon,
    type: "debit",
    label: "Debit Card",
    name: "Andres Nora",
    number: "**** **** **** 2837",
    expiry: {
      month: "06",
      year: "25",
    },
  },
];

export const transactionsData = [
  {
    id: 1,
    code: "#mE843643",
    date: "May 12, 2021",
    type: "deposit",
    amount: "$380.00",
    payment: "****2345",
    number: "8463453",
    status: "paid",
    detail: "...",
  },
  {
    id: 2,
    code: "#mE843643",
    date: "May 12, 2021",
    type: "deposit",
    amount: "$380.00",
    payment: "****2345",
    number: "8463453",
    status: "paid",
    detail: "...",
  },
];
