import { Addresses } from "../../fake-db/addresses/addresses.data";
import { ACCOUNT_ACTION_TYPES } from "./account.types";



const ACCOUNT_INITIAL_STATE = {
    loading: false,
    verifyEmailLoading: false,
    verifyEmailModalLoading: false,
    verifyNumberLoading: false,
    verifyNumberModalLoading: false,
    verifyEmailModal: false,
    verifyNumberModal: false,
    genders: ['male', 'female', 'other'],
    addresses: Addresses,
    data: {
        name: '',
        family: '',
        gender: '',
        avatar: '',
        email: '',
        username: '',
        number: '',
        country: '',
        email_verify: null,
        number_verify: null,

    },
    errors: {
        name: [],
        family: [],
        gender: [],
        avatar: [],
        email: [],
        username: [],
        number: [],
        country: []
    },

}
export const accountResucer = (state = ACCOUNT_INITIAL_STATE, action = {}) => {
    const { type, payload } = action

    switch (type) {

        
        case ACCOUNT_ACTION_TYPES.SET_ACCOUNT_LOADING:
            return { ...state, [payload.name] : payload.value }


        case ACCOUNT_ACTION_TYPES.SET_PERSONAL_INFO_DATA:
            return { ...state, data: payload }

        case ACCOUNT_ACTION_TYPES.GET_PERSONAL_INFO_START:
            return { ...state, errors: {}, loading: true }

        case ACCOUNT_ACTION_TYPES.GET_PERSONAL_INFO_SUCCESS:
            {
                return { ...state, errors: {}, loading: false, data: payload }
            }

        case ACCOUNT_ACTION_TYPES.GET_PERSONAL_INFO_FAILED:
            return { ...state, errors: {payload}, loading: false }


        case ACCOUNT_ACTION_TYPES.UPDATE_PERSONAL_INFO_START:
            return { ...state,  loading: true, verifyEmailModalLoading : false,   errors: {} }
        case ACCOUNT_ACTION_TYPES.UPDATE_PERSONAL_INFO_SUCCESS:
            return { ...state,  loading: false, errors: {}, data: payload }
        case ACCOUNT_ACTION_TYPES.UPDATE_PERSONAL_INFO_FAILED:
            return { ...state, errors: payload, loading: false }

        case ACCOUNT_ACTION_TYPES.SET_VERIFY_EMAIL_MODAL:
            return { ...state, verifyEmailModal: payload }
        case ACCOUNT_ACTION_TYPES.SET_VERIFY_NUMBER_MODAL:
            return { ...state, verifyNumberModal: payload }

        default:
            return state
    }
}