import { HELO_ACTION_TYPES } from "./help.types"

const HELP_INITIAL_STATE = {
    loading: false,
    data: {
        search: ''
    },
    searchButtonChildren: 'Search Now',
    boxPadding: '8px 8px 8px 25px'
}


export const helpReducer = (state = HELP_INITIAL_STATE, action = {}) => {
    const { type, payload } = action

    switch (type) {
        case HELO_ACTION_TYPES.SET_SEARCH_BUTTIN_CHILDREN:
            return { ...state, searchButtonChildren: payload }
        case HELO_ACTION_TYPES.SET_BOX_PADDING:
            return { ...state, boxPadding: payload }

        default:
            return state
    }
}