import "./announcement-box-item.style.scss";
const AnnouncementItemBox = ({
  name,
  value,
  active = false,
  onSelect,
  title,
  image,
}) => {
  const handelClick = () => {
    onSelect({ name, value});
  };
  return (
    <div
      className={`announcement-item-box  ${active ? "active" : ""}`}
      onClick={handelClick}
    >
      {image != null &&  (
        <img
          className="announcement-item-box-image"
          src={image}
          alt={title}
        />
      )}
      <p className="announcement-item-box-text">{title}</p>
    </div>
  );
};

export default AnnouncementItemBox;
