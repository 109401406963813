import i18n from 'i18next';
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

const getCurrentHost =
process.env.NODE_ENV !== "production"
    ? "http://localhost:3000"
    : `https://${window.location.hostname}`;

i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: `${getCurrentHost}/locales/{{lng}}/translation.json`,
    },
  });

export default i18n;