import { creditCardsData } from "../../fake-db/payments-payouts/payments-payouts.data";
import { PAYMENT_ACTION_TYPES } from "./payment.types";



const PAYMENT_INITIAL_STATE = {
    creditCards: creditCardsData,
    loading: false,
}
export const paymentReducer = (state = PAYMENT_INITIAL_STATE, action = {}) => {
    const { type, payload } = action

    switch (type) {
        // case PAYMENT_ACTION_TYPES.te:
        //     return { ...state }
        default:
            return state
    }
}