import Avatar01 from "../../assets/images/avatars/01.png";
import Avatar02 from "../../assets/images/avatars/02.png";
import Avatar03 from "../../assets/images/avatars/03.png";
import Avatar04 from "../../assets/images/avatars/04.png";

const images = [
    Avatar01,
    Avatar02,
    Avatar03,
    Avatar04
];

export const Reviews = Array.from({ length: 4 }, (_, i) => {
  return {
    id: i,
    image: images[i],
    stars: Math.floor(Math.random() * (5 - 4 + 1)) + 4,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga culpa aperiam earum quae velit maxime id, voluptatem harum officiis laudantium possimus natus sequi vero illum, magni quod aliquam consequatur esse!",
    name: "Jack Johnson",
    location: "New York, USA",
  };
});
