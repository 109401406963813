import { toast } from "react-hot-toast";
import {
  fetchCategoriesFailed,
  fetchCategoriesStart,
  fetchCategoriesSuccess,
} from "./categories.action";
import GetCategories from "../../services/category/category.api";

export const getCategories = (data) => async (dispatch) => {
  dispatch(fetchCategoriesStart());
  try {
    const res = await GetCategories(data);
    dispatch(fetchCategoriesSuccess(res.data.data.categories));
  } catch (error) {
    toast.error(error.message);
    dispatch(fetchCategoriesFailed(error));
  }
};

