export const ACCOUNT_ACTION_TYPES = {
    // personal information ------------------------------------>
    SET_ACCOUNT_LOADING : 'account/personal-information/SET_ACCOUNT_LOADING',

    SET_PERSONAL_INFO_DATA : 'account/personal-information/SET_PERSONAL_INFO_DATA',
    GET_PERSONAL_INFO_START: 'account/personal-information/GET_PERSONAL_INFO_START',
    GET_PERSONAL_INFO_SUCCESS: 'account/personal-information/GET_PERSONAL_INFO_SUCCESS',
    GET_PERSONAL_INFO_FAILED: 'account/personal-information/GET_PERSONAL_INFO_FAILED',

    UPDATE_PERSONAL_INFO_START: 'account/personal-information/UPDATE_PERSONAL_INFO_START',
    UPDATE_PERSONAL_INFO_SUCCESS: 'account/personal-information/UPDATE_PERSONAL_INFO_SUCCESS',
    UPDATE_PERSONAL_INFO_FAILED: 'account/personal-information/UPDATE_PERSONAL_INFO_FAILED',

    SET_VERIFY_EMAIL_MODAL : 'account/personal-information/SET_VERIFY_EMAIL_MODAL',
    SET_VERIFY_NUMBER_MODAL : 'account/personal-information/SET_VERIFY_NUMBER_MODAL',

}