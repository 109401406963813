import "./add-announcement-select-option.style.scss";
import { ReactComponent as HouseIcon } from "./../../../../../assets/images/Icons/category/house.svg";
import AnnouncementItemBox from "../../../../forms/announcement-box-item/announcement-box-item.component";
import AddAnnouncementHeader from "../../header/add-announcement-header.component";

const AddAnnouncementSelectOption = () => {
  return (
    <div className="add-announcement-select-option">
      <AddAnnouncementHeader
        title={"Tell guests what your place has to offer"}
        description={
          "You can add more amenities after you publish your listing."
        }
      />
      <div className="add-announcement-select-option-items">
        {Array.from({ length: 20 }).map((item) => (
          <AnnouncementItemBox title={"House"} icon={<HouseIcon />} />
        ))}
      </div>
    </div>
  );
};

export default AddAnnouncementSelectOption;
