// * Styles
import { useState } from "react";
import FormIcon from "../icon/icon.component";
import "./textarea.styles.scss";

export const TEXTAREA_STYLES = {
  base: "base-textarea",
  outline: "outline-textarea",
};

const Textarea = ({
  label,
  labelIsVisible = true,
  name,
  placeholder,
  cols,
  rows = 4,
  icon,
  val,
  onChange = () => {},
  errors = [],
  style = TEXTAREA_STYLES.primary,
  children = "",
  counter = false,
  limit,
  ...otherProps
}) => {
  const id = label
    ? label.split(" ").join("-").toLowerCase() + "-form"
    : "form";
  placeholder = placeholder ? placeholder : label;

  const [length, setLength] = useState(0);

  const handleChange = (value) => {
    if (counter) setLength(value.length);
    onChange({ value, name });
  };

  return (
    <div className="app-form-group">
      <div
        className={`form-style form-textarea-control ${style}`}
        {...otherProps}
      >
        {icon && <FormIcon icon={icon} className="form-textarea-icon" />}

        <textarea
          className="form-textarea-field"
          name={name}
          id={id}
          cols="30"
          placeholder={placeholder}
          rows={rows}
          onChange={(e) => handleChange(e.target.value)}
        >
          {val}
        </textarea>

        {labelIsVisible && (
          <label htmlFor={id} className="form-textarea-label text-capitalize">
            {label}
          </label>
        )}
      </div>
      {counter && (
        <div className="form-textarea-counter">
          <p className="form-textarea-counter-length">{length}</p>/
          <p className="form-textarea-counter-limit">{limit}</p>
        </div>
      )}
      {errors && (
        <div className="form-error-items">
          {errors.map((error, i) => (
            <div key={i} className="form-error-item">
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Textarea;
