import "./yes-no-question.style.scss";

const YesNoQuestion = ({ title = "", yesText = "Yes", noText = "No" }) => {
  return (
    <div className="yes-no-question">
      <h3 className="yes-no-question-title">{title}</h3>
      <div className="yes-no-question-items">
        <div className="yes-no-question-item">
          <h5 className="yes-no-question-item-text">{yesText}</h5>
          <input type="radio" className="yes-no-question-item-action" />
        </div>
        <div className="yes-no-question-item">
          <h5 className="yes-no-question-item-text">{noText}</h5>
          <input type="radio" className="yes-no-question-item-action" />
        </div>
      </div>
    </div>
  );
};

export default YesNoQuestion;
