import "./add-announcement-header.style.scss";
const AddAnnouncementHeader = ({ title, description }) => {
  return (
    <div className="add-announcement-header">
      <h3 className="add-announcement-header-title">{title}</h3>
      {description && (
        <p className="add-announcement-header-description">
          {description}
        </p>
      )}
    </div>
  );
};

export default AddAnnouncementHeader;
