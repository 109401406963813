import "./add-announcement-category.style.scss";
import { ReactComponent as HouseIcon } from "./../../../../assets/images/Icons/category/house.svg";
import AnnouncementItemBox from "../../../forms/announcement-box-item/announcement-box-item.component";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../../../store/categories/categories.thunk";
import { selectCategories } from "./../../../../store/categories/categories.selector";
import { setAddAnnouncementData } from "./../../../../store/add-announcement/add-announcement.action";
import { selectAddAnnouncementFormData } from "../../../../store/add-announcement/add-announcement.selector";

export const AnnouncementCategoryTypes = {
  MAIN_CATEGORY: "main_category",
  CATEGORY: "category",
  SUB_CATEGORY: "sub_category",
};

const AddAnnouncementCategory = ({
  type = AnnouncementCategoryTypes.MAIN_CATEGORY,
  title = "Select your main category ...",
}) => {
  const dispatch = useDispatch();
  const filterCategories = (intialCategories) => {
    switch (type) {
      case AnnouncementCategoryTypes.MAIN_CATEGORY:
        return intialCategories.filter((item) => item.type == "c1");
      case AnnouncementCategoryTypes.CATEGORY:
        return intialCategories.filter(
          (item) =>
            item.type == "c2" && item.categoryId == formData.main_category
        );
      case AnnouncementCategoryTypes.SUB_CATEGORY:
        return intialCategories.filter(
          (item) => item.type == "c3" && item.categoryId == formData.category
        );
      default:
        return intialCategories;
    }
  };
  const checkActive = (id) => {
    if (
      type == AnnouncementCategoryTypes.MAIN_CATEGORY &&
      id == formData.main_category
    )
      return true;
    else if (
      type == AnnouncementCategoryTypes.CATEGORY &&
      id == formData.category
    )
      return true;
    else if (
      type == AnnouncementCategoryTypes.SUB_CATEGORY &&
      id == formData.sub_category
    )
      return true;
    return false;
  };
  const typeToString = () => {
    switch (type) {
      case AnnouncementCategoryTypes.MAIN_CATEGORY:
        return "main_category";
      case AnnouncementCategoryTypes.CATEGORY:
        return "category";
      case AnnouncementCategoryTypes.SUB_CATEGORY:
        return "sub_category";
      default:
        return "main_category";
    }
  };
  const formData = useSelector(selectAddAnnouncementFormData);
  const categories = filterCategories(useSelector(selectCategories));
  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const handleFormData = ({ value, name }) => {
    dispatch(setAddAnnouncementData({ ...formData, [name]: value }));
  };

  return (
    <div className="add-announcement-category">
      <h3 className="add-announcement-category-title">{title}</h3>
      <div className="add-announcement-category-items">
        {categories.length > 0 &&
          categories.map((item) => (
            <AnnouncementItemBox
              active={checkActive(item.id)}
              onSelect={handleFormData}
              name ={typeToString()}
              key={item.id}
              value={item.id}
              item={item}
              type={type}
              title={item.name}
              image ={type == AnnouncementCategoryTypes.MAIN_CATEGORY ? item.logo : null}
            />
          ))}
      </div>
    </div>
  );
};

export default AddAnnouncementCategory;
