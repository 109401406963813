import AnnouncementOptionItem from "../../../../forms/announcement-option-item/announcement-option-item.component";
import YesNoQuestion from "../../../../forms/yes-no-question/yes-no-question.component";
import "./add-announcement-option.style.scss";

const AddAnnouncementOption = () => {
  return (
    <div className="add-announcement-option">
      <h3 className="add-announcement-option-title">
        Let's start with the basics
      </h3>
      <div className="add-announcement-option-body">
        <div className="announcement-option-section">
          <h4 className="announcement-option-section-title">
            How many people can stay here?
          </h4>
          <div className="announcement-option-section-content">
            <AnnouncementOptionItem actionText={"0.5"} title={"test"} />
            <AnnouncementOptionItem actionText={"0.5"} title={"test"} />
          </div>
        </div>
        <YesNoQuestion title="Does every bedroom have a lock?" />
      </div>
    </div>
  );
};

export default AddAnnouncementOption;
