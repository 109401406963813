import { AUTH_FORM_TYPES } from "../../components/auth/auth-types/auth-types.component";
import { AUTH_ACTION_TYPES } from "./auth.types";
const AUTH_INITIAL_STATE = {
  type: "signIn",
  verifyType: 'verify',
  formTypes: [AUTH_FORM_TYPES.CREDENTIALS, AUTH_FORM_TYPES.NUMBER],
  formTypeActive: AUTH_FORM_TYPES.CREDENTIALS,
  data: {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    number: "",
    country: null,
    rememberMe: false,
    agreement: false,
    code: "",
  },
  errors: {
    username: [],
    email: [],
    password: [],
    confirmPassword: [],
    number: [],
    country: [],
    code: []
  },
  isLoading: false,
  user: {
    username: '',
    fullName: '',
    email: '',
    avatar: '',
    number: '',
    referral: '',
    key: ''
  },
  login: false,
  verify: false,
  forgotPassword: false,
  changePassword: false,
  register: false,
  generateKey: false
};

export const authReducer = (state = AUTH_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case AUTH_ACTION_TYPES.SET_AUTH_TYPE:
      return { ...state, type: payload };
    case AUTH_ACTION_TYPES.SET_AUTH_FORM_TYPE_ACTIVE:
      return { ...state, formTypeActive: payload };
    case AUTH_ACTION_TYPES.SET_AUTH_DATA:
      return { ...state, data: payload };
    case AUTH_ACTION_TYPES.CLEAR_AUTH_DATA:
      return { ...state, data: AUTH_INITIAL_STATE.data };
    case AUTH_ACTION_TYPES.CLEAR_AUTH_ERRORS:
      return { ...state, errors: AUTH_INITIAL_STATE.errors };
    case AUTH_ACTION_TYPES.REGISTER_USER_START:
      return { ...state, isLoading: true };
    case AUTH_ACTION_TYPES.REGISTER_USER_SUCCESS:
      return { ...state, isLoading: false, errors: {}, user: payload, register: true };
    case AUTH_ACTION_TYPES.REGISTER_USER_FAILED:
      return { ...state, isLoading: false, errors: payload };

    // login
    case AUTH_ACTION_TYPES.LOGIN_USER_START:
      return { ...state, isLoading: true }
    case AUTH_ACTION_TYPES.LOGIN_USER_SUCCESS:
      return { ...state, isLoading: false, login: true, errors: {}, user: payload, verify: payload.verify }
    case AUTH_ACTION_TYPES.LOGIN_USER_FAILED:
      return { ...state, isLoading: false, errors: payload }

    // verify
    case AUTH_ACTION_TYPES.SET_VEIFY_TYPE:
      return { ...state, verifyType: payload }
    case AUTH_ACTION_TYPES.SET_VEIFY_CODE:
      return { ...state, data: { ...state.data, code: payload } }
    case AUTH_ACTION_TYPES.VERIFY_USER_START:
      return { ...state, isLoading: true, errors: {} }
    case AUTH_ACTION_TYPES.VERIFY_USER_SUCCESS:
      return { ...state, isLoading: false, errors: {}, user: payload, verify: true }
    case AUTH_ACTION_TYPES.VERIFY_USER_FAILED:
      return { ...state, isLoading: false, errors: payload }

    // forgot password
    case AUTH_ACTION_TYPES.FORGOT_PASSWORD_START:
      return { ...state, isLoading: true, errors: {}, forgotPassword: false }
    case AUTH_ACTION_TYPES.FORGOT_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, errors: {}, user: payload, verify: false, forgotPassword: true }
    case AUTH_ACTION_TYPES.FORGOT_PASSWORD_FAILED:
      return { ...state, isLoading: false, errors: payload, forgotPassword: false }

    // change password
    case AUTH_ACTION_TYPES.CHANGE_PASSWORD_START:
      return { ...state, isLoading: true, errors: {}, changePassword: false }
    case AUTH_ACTION_TYPES.CHANGE_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, errors: {}, user: payload, changePassword: true }
    case AUTH_ACTION_TYPES.CHANGE_PASSWORD_FAILED:
      return { ...state, isLoading: false, errors: payload, changePassword: false }

    // generate key
    case AUTH_ACTION_TYPES.GENERATE_KEY_START:
      return { ...state, isLoading: true, errors: {}, generateKey: false }
    case AUTH_ACTION_TYPES.GENERATE_KEY_SUCCESS:
      {
        // dispatch(setVerifyEmailModal(false))
        return { ...state, isLoading: false, errors: {}, user: { ...state.user, key: payload }, generateKey: true }
      }
    case AUTH_ACTION_TYPES.CHANGE_PASSWORD_FAILED:
      return { ...state, isLoading: false, errors: payload, generateKey: false }

    // default
    default:
      return state;
  }
};
