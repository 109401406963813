import './map-card.styles.scss';
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import DefaultMarker from '../../assets/images/Icons/map-marks/default-marker.png';

export const MAP_TYPES = {
    SET_LOCATION: 'set-location',
    SHOW_LOCATIONS: 'show-locations',
}

const MapCard = ({ type = MAP_TYPES.SHOW_LOCATIONS, allMarkers, zoomOnScroll = true, className, location, defaultLocation = {lat: 36, lng: 0}}) => {
    const [markers, setMarkers] = useState(allMarkers);
    const [center, setCenter] = useState(location);
    const [mapZoom, setMapZoom] = useState(14.5);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    })

    useEffect(() => {
        setMarkers(allMarkers);
    }, [allMarkers]);

    useEffect(() => {
        if(location !== null && location !== undefined){
            setCenter(location);
            setMapZoom(14.5)
        } else {
            setCenter(defaultLocation);
            setMapZoom(1)
        }
    }, [location]);

    const onUnmount = () => setMarkers(null)

    if (isLoaded) {
        return (
            <GoogleMap
                mapContainerClassName={`map-container ${className}`}
                onUnmount={onUnmount}
                options={{
                    zoom: mapZoom,
                    center: center,
                    gestureHandling: zoomOnScroll ? 'greedy' : false,
                    disableDefaultUI: true,
                    keyboardShortcuts: false,
                    backgroundColor: '#EFEFEF',
                    styles: [
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            featureType: "poi.business",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            "featureType": "landscape.man_made",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "hue": "#f49935"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "hue": "#fad959"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "hue": "#a1cdfc"
                                },
                                {
                                    "saturation": 30
                                },
                                {
                                    "lightness": 49
                                }
                            ]
                        }
                    ]
                }}
            >
                {markers && markers.map((marker, i) => (
                    <MarkerF
                        position={marker.location}
                        icon={DefaultMarker}
                        key={i}
                    />
                ))}
            </GoogleMap>
        )
    } else {
        return (
            <span>Loading...</span>
        )
    }
}

export default MapCard;