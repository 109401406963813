import "./announcement-option-item.style.scss";

const AnnouncementOptionItem = ({ title, text = null, actionText }) => {
  return (
    <div className="announcement-option-item">
      <div className="announcement-option-item-info">
        <h5 className="announcement-option-item-title">{title}</h5>
        {text && <p className="announcement-option-item-text">{text}</p>}
      </div>
      <div className="announcement-option-item-actions">
        <div className="announcement-option-item-action">-</div>
        <p className="announcement-option-item-actions-text">{actionText}</p>
        <div className="announcement-option-item-action">+</div>
      </div>
    </div>
  );
};

export default AnnouncementOptionItem;
