import { Products } from "../../fake-db/products/products";
import { ANNOUNCEMENT_ACTION_TYPES } from "./announcement.types";

const ANNOUNCEMENT_INITIAL_STATE = {
  announcements: Products,
  announcement: Products[0],
  filters: {
    location: "",
    date: {
      from: null,
      to: null,
    },
    category: "",
  },
  type: 'rent',
};

export const announcementReducer = (
  state = ANNOUNCEMENT_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
    case ANNOUNCEMENT_ACTION_TYPES.SET_LOCATION_FILTER:
      return { ...state, filters: { ...state.filters, location: payload } };
    case ANNOUNCEMENT_ACTION_TYPES.SET_DATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          date: { from: payload?.from, to: payload?.to },
        },
      };
    case ANNOUNCEMENT_ACTION_TYPES.SET_CATEGORY_FILTER:
      return { ...state, filters: { ...state.filters, category: payload } };
    case ANNOUNCEMENT_ACTION_TYPES.RESET_DATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          date: { from: null, to: null },
        },
      };
    case ANNOUNCEMENT_ACTION_TYPES.TOGGLE_ANNOUNCEMENT_TYPE:
      return {...state, type: payload}
    default:
      return state;
  }
};
