import { createSelector } from "reselect";

const selectAddAnnouncementReducer = (state) => state.addAnnouncement;

export const selectLoading = createSelector(
  [selectAddAnnouncementReducer],
  (slice) => slice?.loading
);

export const selectAddAnnouncementCurrentStep = createSelector(
  [selectAddAnnouncementReducer],
  (slice) => slice?.current_step
);
export const selectAddAnnouncementFormData = createSelector(
  [selectAddAnnouncementReducer],
  (slice) => slice?.data
);
