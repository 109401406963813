import { ADD_ANNOUNCEMENT_STEPS } from "../../components/announcement/add-announcement/add-announcement.component";
import { ADD_ANNOUNCEMENT_ACTION_TYPES } from "./add-announcement.types";

const ADD_ANNOUNCEMENT_INITIAL_STATE = {
  loading: false,
  current_step: ADD_ANNOUNCEMENT_STEPS.MAIN_CATEGORY,
  data: {
    main_category: 0,
    category: 0,
    sub_category: 0,
    location: '',
    title : '',
    description : ''
  },
};
export const addAnnouncementReducer = (
  state = ADD_ANNOUNCEMENT_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_ANNOUNCEMENT_ACTION_TYPES.SET_ADD_ANNOUNCEMENT_STEP:
      return { ...state, current_step: payload };
    case ADD_ANNOUNCEMENT_ACTION_TYPES.SET_ADD_ANNOUNCEMENT_DATA: {
      return { ...state, data: payload };
    }
    default:
      return state;
  }
};
