import "./add-announcement-description.style.scss";
import AddAnnouncementHeader from "../../header/add-announcement-header.component";
import Textarea from "./../../../../forms/textarea/textarea.component";
import { useDispatch, useSelector } from "react-redux";
import { setAddAnnouncementData } from "../../../../../store/add-announcement/add-announcement.action";
import { selectAddAnnouncementFormData } from "../../../../../store/add-announcement/add-announcement.selector";
const AddAnnouncementDescription = () => {
  const dispatch = useDispatch();
  const formData = useSelector(selectAddAnnouncementFormData);
  const handleFormData = ({ value, name }) => {
    dispatch(setAddAnnouncementData({ ...formData, [name]: value }));
  };
  return (
    <div className="add-announcement-title">
      <AddAnnouncementHeader
        title={"Create your description"}
        description={"Share what makes your place special.          "}
      />
      <div className="add-announcement-title-body">
        <Textarea
        onChange={handleFormData}
          name={"description"}
          label={"Description"}
          placeholder={"Enter Your Description ..."}
          rows={10}
          counter={true}
          limit={500}
        />
      </div>
    </div>
  );
};

export default AddAnnouncementDescription;
