import { News } from "../../fake-db/news/news";
import { NEWS_ACTION_TYPES } from "./news.types";

const NEWS_INITIAL_STATE = {
  news: News,
  loading: false,
  errors: [],
};

export const newsReducer = (state = NEWS_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    default:
      return state;
  }
};
