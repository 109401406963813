import ApiHandler from "../config.api";

function FetchSettings() {
    const settings = ApiHandler.get('settings')
        .then(function (res) {
            return res;
        })
        .catch((error) => {
            return error;
        });
    return settings;
}

export default FetchSettings;