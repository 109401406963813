import { combineReducers } from "redux";
import { accountResucer } from "./account/account.reducer";
import { announcementReducer } from "./announcement/announcement.reducer";
import { authReducer } from "./auth/auth.reducer";
import { countryReducer } from "./country/country.reducer";
import { helpReducer } from "./help/help.reducer";
import { navigationReducer } from "./navigation/navigation.reducer";
import { settingReducer } from "./setting/setting.reducer";
import { userReducer } from "./user/user.reducer";
import { newsReducer } from "./news/news.reducer";
import { categoriesReducer } from "./categories/categories.reducer";
import { addAnnouncementReducer } from "./add-announcement/add-announcement.reducer";
import { paymentReducer } from "./payment/payment.reducer";

export const rootReducer = combineReducers({
    auth: authReducer,
    country: countryReducer,
    user: userReducer,
    account : accountResucer,
    navigation: navigationReducer,
    setting: settingReducer,
    help : helpReducer,
    announcement: announcementReducer,
    news: newsReducer,
    categories: categoriesReducer,
    addAnnouncement : addAnnouncementReducer,
    payment: paymentReducer,
})