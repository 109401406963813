import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@chakra-ui/spinner";
import classnames from 'classnames';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
// * Styles
import "./add-announcement-address.style.scss";
// * Components
import MapCard, { MAP_TYPES } from "./../../../../map-card/map-card.component";
import Input from "../../../../forms/input/input.component";
import ContextDropdown from "../../../../extended-ui/context-dropdown/context-dropdown.component";
// * Redux Store
import { selectAddAnnouncementFormData } from "../../../../../store/add-announcement/add-announcement.selector";
import { setAddAnnouncementData } from "../../../../../store/add-announcement/add-announcement.action";
// * Icons & Images
import { ReactComponent as LocationIconSvg } from '../../../../../assets/images/Icons/location-icon.svg';
import { ReactComponent as CloseIconSvg } from '../../../../../assets/images/Icons/close-square-icon.svg';

const AddAnnouncementAddress = () => {
  const dispatch = useDispatch();
  const formData = useSelector(selectAddAnnouncementFormData);
  const selectedLocation = formData?.location;
  const [locationsVisibility, setLocationsVisibility] = useState(false);
  const [locations, setLocations] = useState([]);
  const [address, setAddress] = useState(selectedLocation ? selectedLocation?.description : '');
  const [mapLocation, setMapLocation] = useState();
  const [mapMarkers, setMapMarkers] = useState();

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  useEffect(() => {
    placePredictions.length > 0 && setLocations(placePredictions);
  }, [placePredictions]);

  useEffect(() => {
    setAddress(selectedLocation ? selectedLocation.description : '');
    getPlacePredictions({ input: selectedLocation?.description });
  }, [selectedLocation]);

  useEffect(() => {
    handleMapLocationData(selectedLocation);
  }, [placesService, selectedLocation]);

  const handleMapLocationData = (loc) => {
    if(loc) {
        placesService?.getDetails(
        { placeId: loc.place_id },
        (placeDetails) => {
          let location = {lat: placeDetails.geometry?.location?.lat(), lng: placeDetails.geometry?.location?.lng()};
          handleMapLocationChange(location);
      });
    }
  }

  const handleMapLocationChange = (loc) => {
    if(loc) {
      setMapLocation(loc);
      setMapMarkers([{location: loc}]);
    }
  }

  const handleLocationSearchChange = (e) => {
    let val = e.value;
    setAddress(val);
    if(val.length > 2) {
      !locationsVisibility && setLocationsVisibility(true);
      getPlacePredictions({ input: val });
    } else {
      setLocationsVisibility(false);
    }
  }

  const clearMapLocationAndMarker = () => {
    setMapLocation(null);
    setMapMarkers([]);
  }

  const handleLocationClear = () => {
    setLocationsVisibility(false);
    setAddress('');
    dispatch(setAddAnnouncementData({ ...formData, location: '' }));
    clearMapLocationAndMarker()
  }

  const handleLocationClick = (loc) => {
    setLocationsVisibility(false);
    setAddress('');
    dispatch(setAddAnnouncementData({ ...formData, location: loc }));
  }

  const LocationItems = () => (
    <ul className="add-announcement-location-items">
      {locations && locations.length > 0 && locations.map((location, i) => (
        <li className={classnames("add-announcement-location-item", selectedLocation?.place_id === location?.place_id ? 'active' : null)} key={i} onClick={() => handleLocationClick(location)}>
          <span className="announcement-location-icon">
            <LocationIconSvg />
          </span>
          <span className="announcement-location-address">{location?.description}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="add-announcement-address">
      <div className="add-announcement-address-header">
        <h3 className="add-announcement-address-title">
          Where's your place located?
        </h3>
        <p className="add-announcement-address-description">
          Your address is only shared with guests after they’ve made a
          reservation.
        </p>
      </div>
      <div className="add-announcement-address-body">
        <div className="add-announcement-address-search">
          <div className="add-announcement-address-actions">
            <Input className="add-announcement-address-input" label="Your location" val={address} onChange={(e) => handleLocationSearchChange(e)} />
            <CloseIconSvg className={classnames("add-announcement-address-clear", address && address.length > 0 ? 'visible' : null)} onClick={handleLocationClear} />
          </div>
          <ContextDropdown className="add-announcement-locations" active={locationsVisibility}>
            {isPlacePredictionsLoading ? <Spinner className="app-btn-loading" width={30} height={30} color="#9B9B9B" speed="500ms" thickness="3px" /> : (
              <LocationItems />
            )}
          </ContextDropdown>
        </div>
        <MapCard type={MAP_TYPES.SET_LOCATION} location={mapLocation} allMarkers={mapMarkers}/>
      </div>
    </div>
  );
};

export default AddAnnouncementAddress;
