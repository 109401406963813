import { NAVIGATION_ACTION_TYPES } from "./navigation.types";

const NAVIGATION_INITIAL_STATE = {
  collapse: false,
  navbar: {
    position: "initial",
  },
};

export const navigationReducer = (state = NAVIGATION_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case NAVIGATION_ACTION_TYPES.SET_COLLAPSE:
      return { ...state, collapse: payload };
    case NAVIGATION_ACTION_TYPES.SET_NAVBAR_POSITION:
      return {
        ...state,
        navbar: {
          ...state.navbar,
          position: payload,
        },
      };
    default:
      return state;
  }
};
