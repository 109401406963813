import aboutCoverImage from '../../assets/images/about/about-us-cover.png';
import Member01Image from '../../assets/images/about/members/member-01.png';
import Member02Image from '../../assets/images/about/members/member-02.png';

export const AboutData = {
    image: aboutCoverImage,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    members: [
        {
            id: 1,
            name: 'Palm Harbor',
            role: 'Manager',
            image: Member01Image,
        },
        {
            id: 2,
            name: 'Palm Harbor',
            role: 'Manager',
            image: Member02Image,
        },
        {
            id: 3,
            name: 'Palm Harbor',
            role: 'Manager',
            image: Member01Image,
        },
        {
            id: 4,
            name: 'Palm Harbor',
            role: 'Manager',
            image: Member02Image,
        },
    ]
}