import axios from 'axios';
import { store } from "./../store/store";

const token = () => {
    const token = 'Bearer '+ store.getState()?.auth?.user?.token
    return token
};

const BaseURL = () => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:8000';
    const ver = process.env.REACT_APP_API_VER ? process.env.REACT_APP_API_VER : 'v1';
    return `${url}/api/${ver}/`
}

const ApiHandler = axios.create({
    baseURL: BaseURL()
});


ApiHandler.interceptors.request.use(function (config) {
    config.headers.Authorization = token();
    return config;
});

// ApiHandler.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';
ApiHandler.defaults.headers.common['Accept'] = 'application/json';
ApiHandler.interceptors.response.use(
    response => {
        if (response.data.success === true) {
            return response
        }
        return Promise.reject(response.data)
    },
    error => {
        if (!error.response) {
            return Promise.reject({
                message: error.message,
                success: false,
                data: {}
            })

        } else if (error.response) {
            return Promise.reject(error.response.data);
        }

    }
)

export default ApiHandler;