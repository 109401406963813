import ApiHandler from "../config.api";

function GetCountries() {
    const countires = ApiHandler.get('countries')
        .then(function (res) {
            return res;
        })
        .catch((error) => {
            return error;
        });
    return countires;
}

export default GetCountries;