import { UserData } from "../../fake-db/user/user.data";
import { USER_ACTION_TYPES } from "./user.types";

const USER_INITIAL_STATE = {
  user: UserData,
  error: null,
};

export const userReducer = (state = USER_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTION_TYPES.SET_USER:
      return { ...state, user: payload };
    default:
      return state;
  }
};
