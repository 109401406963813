import { createSelector } from "reselect";

const selectAuthReducer = (state) => state.auth;

export const selectAuthType = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.type
);

export const selectLoading = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.isLoading
);

export const selectAuthFormTypes = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.formTypes
);

export const selectAuthFormTypeActive = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.formTypeActive
);

export const selectAuthData = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.data
);

export const selectAuthErrors = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.errors
);
export const selectVerifyCode = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.data.code
);
export const selectLoginStatus = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.login
);
export const selectUser = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.user
);
export const selectVerify = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.verify
);
export const selectForgotPassword = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.forgotPassword
);
export const selectChangePassword = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.changePassword
);

export const selectVerifyType = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.verifyType
)

export const selectRegister = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.register
)

export const selectAuthKey = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.user?.key
)

export const selectAuthStatus = createSelector(
    [selectAuthReducer],
    (authSlice) => ((authSlice.login || authSlice.register) && authSlice.verify) && ('token' in authSlice.user)
)