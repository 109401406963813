import { AboutData } from "../../fake-db/about/about.data";
import { Countries } from "../../fake-db/countries/countries";
import { LocalesData } from "../../fake-db/locales/locales.data";
import { Reviews } from "../../fake-db/reviews/reviews";
import { SETTING_ACTION_TYPES } from "./setting.types";

const SETTING_INITIAL_STATE = {
  about: AboutData,
  locales: LocalesData,
  countries: Countries,
  reviews: Reviews,
  loading: false,
  errors: [],
};

export const settingReducer = (state = SETTING_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SETTING_ACTION_TYPES.FETCH_SETTINGS_START:
      return { ...state, loading: true };
    case SETTING_ACTION_TYPES.FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
        locales: payload.locales,
        countries: payload.countries,
      };
    case SETTING_ACTION_TYPES.FETCH_SETTINGS_FAILED:
      return { ...state, loading: false, errors: [payload] };
    default:
      return state;
  }
};
