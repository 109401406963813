// * Styles
import './input.styles.scss';
import FormIcon from '../icon/icon.component';

export const INPUT_STYLES = {
    base: 'base-input',
    outline: 'outline-input',
}

export const INPUT_TYPES = {
    EMAIL: 'email',
    PASSWORD: 'password',
    NUMBER: 'number',
    TEXT: 'text',
    SEARCH: 'search',
}

const Input = ({ label, labelIsVisible = true, name, placeholder, type, icon, val, onChange = () => { }, style = INPUT_STYLES.base, children = '', errors = [], className = '', ...otherProps }) => {
    const id = label ? label.split(" ").join("-").toLowerCase() + '-form' : 'form';
    placeholder = placeholder ? placeholder : label;

    const handleChange = (value) => {
        onChange({ value, name });
    }

    return (
        <div className={`app-form-group ${className}`} >
            <div className={`form-style form-input-control ${style}`} {...otherProps}>
                <div className="form-input-control ">
                    {icon && <FormIcon icon={icon} className="form-input-icon" />}
                    <input type={type} name={name} className="form-input-field" id={id} placeholder={placeholder} value={val} onChange={(e) => handleChange(e.target.value)} />
                    {labelIsVisible && <label htmlFor={id} className='form-input-label text-capitalize'>{label}</label>}
                </div>
            </div>
            {errors && errors.length > 0 && (
                <div className="form-error-items">
                    {errors.map((error, i) => (
                        <div key={i} className="form-error-item">{error}</div>
                    ))}
                </div>
            )}
        </div>

    )

};

export default Input;