import NewsImage01 from "../../assets/images/news/01.png";
import NewsImage02 from "../../assets/images/news/02.png";
import NewsImage03 from "../../assets/images/news/03.png";

const images = [
    NewsImage01,
    NewsImage02,
    NewsImage03,
];

export const News = Array.from({ length: 3 }, (_, i) => {
  return {
    id: i,
    image: images[i],
    title: 'Good laptop features',
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga culpa aperiam earum quae velit maxime id, voluptatem harum officiis laudantium possimus natus sequi vero illum, magni quod aliquam consequatur esse!",
    slug: "good-laptop-features",
  };
});
