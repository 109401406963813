import { Navigate, Route, Routes } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
// * Redux Store
import { getSettings } from "./store/setting/setting.thunk";
import { getCountries } from "./store/country/country.thunk";
// * Components
import LoadingScreen from "./components/loading-screen/loading-screen.component";
// * LazyLoad
const Navigation = lazy(() => import("./routes/navigation/navigation.component"));
const Home = lazy(() => import("./routes/home/home.component"));
const Account = lazy(() => import("./routes/account/account.component"));
const AccountContent = lazy(() => import("./routes/account/account-content/account-content.component"));
const PersonalInformation = lazy(() => import("./routes/account/personal-information/personal-information.component"));
const AuthNavigation = lazy(() => import("./routes/navigation/auth-navigation/auth-navigation.component"));
const SignIn = lazy(() => import("./routes/auth/sign-in/sign-in.component"));
const SignUp = lazy(() => import("./routes/auth/sign-up/sign-up.component"));
const Verify = lazy(() => import("./routes/auth/verify/verify.component"));
const ForgetPassword = lazy(() => import("./routes/auth/forget-password/forget-password.component"));
const ChangePassword = lazy(() => import("./routes/auth/change-password/change-password.component"));
const About = lazy(() => import("./routes/about/about.component"));
const Support = lazy(() => import("./routes/support/support.component"));
const Help = lazy(() => import("./routes/help/help.component"));
const Announcement = lazy(() => import("./routes/announcement/announcement.component"));
const AnnouncementShow = lazy(() => import("./routes/announcement/announcement-show/announcement-show.component"));
const Reserve = lazy(() => import("./routes/reserve/reserve.component"));
const Favorite = lazy(() => import("./routes/account/favorite/favorite.component"));
const Reserves = lazy(() => import("./routes/account/reserve-list/reserves.component"));
const PrivacyAndSecurity = lazy(() => import("./routes/account/privacy-and-security/privacy-and-security.component"));
const DetailProduct = lazy(() => import("./routes/account/detail-product/detail-product.component"));
const DetailProductContent = lazy(() => import("./components/account/account-detail-product/detail-product-content/detail-product-content.component"));
const DetailProductMessages = lazy(() => import("./components/account/account-detail-product/account-detail-product-messages/account-detail-product-messages.component"));
const DetailProductNotifications = lazy(() => import("./components/account/account-detail-product/account-detail-product-notifications/account-detail-product-notifications.component"));
const DetailProductReview = lazy(() => import("./components/account/account-detail-product/account-detail-product-review/account-detail-product.component"));
const DetailProductChat = lazy(() => import("./components/account/account-detail-product/account-detail-product-chat/detail-product-chat.component"));
const AnnouncementNavigation = lazy(() => import("./routes/navigation/announcement-navigation/announcement-navigation.component"));
const AddAnnouncement = lazy(() => import("./components/announcement/add-announcement/add-announcement.component"));
const PaymentsPayouts = lazy(() => import("./routes/account/payments-payouts/payments-payouts.component"));
const ComingSoon = lazy(() => import("./components/pages/coming-soon/coming-soon.component"));

function App() {
  const dispatch = useDispatch();
  // * Set this to false if you want to hide Production Coming Soon...
  const ProductionComingSoon = process.env.NODE_ENV === "production" ? true : false;

  useEffect(() => {
    dispatch(getSettings());
    dispatch(getCountries());
  }, []);

  if(!ProductionComingSoon) {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route path="/announcement/add" element={<AnnouncementNavigation />}>
            <Route index element={<AddAnnouncement />} />
          </Route>
          <Route path="/" element={<Navigation />}>
            <Route index element={<Home />} />
            <Route path="/Products">
              <Route index element={<Announcement />} />
              <Route path=":slug" element={<AnnouncementShow />} />
            </Route>
            <Route path="/About" element={<About />} />
            <Route path="/Support" element={<Support />} />
            <Route path="/Help" element={<Help />} />
            <Route path="/reserve" element={<Reserve />} />
            <Route path="/reserves" element={<Reserves />} />
            <Route path="/Account" element={<Account />}>
              <Route index element={<AccountContent />} />
              <Route
                path="personal-information"
                element={<PersonalInformation />}
              />
              <Route path="payments-payouts">
                <Route index element={<PaymentsPayouts />} />
              </Route>
              <Route
                path="privacy-security"
                element={<PrivacyAndSecurity />}
              />
              <Route path="favorite" element={<Favorite />} />
              <Route path="detail-product" element={<DetailProduct />}>
                <Route index element={<DetailProductContent />} />
                <Route path="messages" element={<DetailProductMessages />} />
                <Route
                  path="notifications"
                  element={<DetailProductNotifications />}
                />
                <Route path="reviews" element={<DetailProductReview />} />
                <Route path="chat" element={<DetailProductChat />} />
              </Route>
            </Route>
          </Route>
          <Route path="/auth" element={<AuthNavigation />}>
            <Route index element={<Navigate to={"/auth/sign-in"} />} />
            <Route path="sign-in" element={<SignIn />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="forget-password" element={<ForgetPassword />} />
            <Route path="verify" element={<Verify />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route
              path="logout"
              element={''}
            />
          </Route>
        </Routes>
      </Suspense>
    );
  } else {
    return (
      <Suspense fallback={<LoadingScreen />}>
          <Routes>
            <Route path="/" element={<ComingSoon />} />
          </Routes>
      </Suspense>
    );
  }

}

export default App;
