// * Icons
import { ReactComponent as HomeIconSvg } from "../../assets/images/Icons/category/house.svg";
import { ReactComponent as CarIconSvg } from "../../assets/images/Icons/category/car.svg";
import { ReactComponent as ServicesIconSvg } from "../../assets/images/Icons/category/services.svg";
import { ReactComponent as ElectronicIconSvg } from "../../assets/images/Icons/category/electronic.svg";
import { ReactComponent as SportsIconSvg } from "../../assets/images/Icons/category/sports.svg";
import { ReactComponent as PhoneIconSvg } from "../../assets/images/Icons/category/phone.svg";

export const CategoriesData = [
  {
    id: 1,
    name: "Home",
    icon: <HomeIconSvg />,
  },
  {
    id: 2,
    name: "Car",
    icon: <CarIconSvg />,
  },
  {
    id: 3,
    name: "Services",
    icon: <ServicesIconSvg />,
  },
  {
    id: 4,
    name: "Electronic",
    icon: <ElectronicIconSvg />,
  },
  {
    id: 5,
    name: "Sports",
    icon: <SportsIconSvg />,
  },
  {
    id: 6,
    name: "Phone",
    icon: <PhoneIconSvg />,
  },
  {
    id: 7,
    name: "Home",
    icon: <HomeIconSvg />,
  },
  {
    id: 8,
    name: "Car",
    icon: <CarIconSvg />,
  },
  {
    id: 9,
    name: "Services",
    icon: <ServicesIconSvg />,
  },
  {
    id: 10,
    name: "Electronic",
    icon: <ElectronicIconSvg />,
  },
  {
    id: 11,
    name: "Sports",
    icon: <SportsIconSvg />,
  },
  {
    id: 12,
    name: "Phone",
    icon: <PhoneIconSvg />,
  },
];
