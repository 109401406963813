// * Icons
import { ReactComponent as SearchSvg } from '../../../assets/images/Icons/form-icons/search-icon.svg';
import { ReactComponent as EmailSvg } from '../../../assets/images/Icons/form-icons/email-icon.svg';
import { ReactComponent as PasswordSvg } from '../../../assets/images/Icons/form-icons/password-icon.svg';
import { ReactComponent as UserSvg } from '../../../assets/images/Icons/form-icons/user-icon.svg';

export const FORM_ICONS = {
    EMAIL: 'email',
    PASSWORD: 'password',
    SEARCH: 'search',
    USER: 'user',
}

const FormIcon = ({ icon, ...otherProps }) => {
    switch (icon) {
        case 'email':
            return <EmailSvg {...otherProps} />
        case 'password':
            return <PasswordSvg {...otherProps} />
        case 'search':
            return <SearchSvg {...otherProps} />
        case 'user':
            return <UserSvg {...otherProps} />
        default:
            return <UserSvg {...otherProps} />
    }
}

export default FormIcon;