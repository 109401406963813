import "./add-announcement-title.style.scss";
import AddAnnouncementHeader from "../../header/add-announcement-header.component";
import Textarea from "./../../../../forms/textarea/textarea.component";
import { useDispatch, useSelector } from "react-redux";
import { setAddAnnouncementData } from "../../../../../store/add-announcement/add-announcement.action";
import { selectAddAnnouncementFormData } from "../../../../../store/add-announcement/add-announcement.selector";
const AddAnnouncementTitle = () => {
  const dispatch = useDispatch();
  const formData = useSelector(selectAddAnnouncementFormData)
  const handleFormData = ({ value, name }) => {
    dispatch(setAddAnnouncementData({ ...formData, [name]: value }));
  };
  return (
    <div className="add-announcement-title">
      <AddAnnouncementHeader
        title={"Now, lets give your house a title"}
        description={
          "Short titles work best. Have fun with it—you can always change it later."
        }
      />
      <div className="add-announcement-title-body">
        <Textarea name={'title'} onChange={handleFormData} label={'Title'} placeholder={'Enter Your Title ...'} rows={5} counter={true} limit={32}/>
      </div>
    </div>
  );
};

export default AddAnnouncementTitle;
