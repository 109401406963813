import './context-dropdown.styles.scss';
import classnames from 'classnames';

export const DROPDOWN_ALIGN = {
    RIGHT: 'right-dropdown',
    LEFT: 'left-dropdown'
}

const ContextDropdown = ({className ,children, align = DROPDOWN_ALIGN.LEFT, active}) => {
    active = active ? 'dropdown-visible' : 'dropdown-invisible';

    return(
        <div className={classnames('app-context-dropdown', align, active)}>
            <div className={classnames("context-dropdown-card", className)}>
                {children}
            </div>
        </div>
    )
}

export default ContextDropdown;