import GetCountries from "../../services/country/country.api"
import { getCountriesFailed, getCountriesStart, getCountriesSuccess } from "./country.action"

export const getCountries = (data) => async (dispatch) => {
    dispatch(getCountriesStart())
    try {
        const res = await GetCountries(data)
        dispatch(getCountriesSuccess(res.data.data.countries))
    } catch (error) {
        dispatch(getCountriesFailed(error))
    }
} 