import {ReactComponent as UsaFlagSvg} from '../../assets/images/flags/usa.svg';

export const Countries = [
    {
        name: 'USA',
        icon: <UsaFlagSvg />,
        value: 1
    },
    {
        name: 'Iran',
        icon: <UsaFlagSvg />,
        value: 98
    },
    {
        name: 'Germany',
        icon: <UsaFlagSvg />,
        value: 49
    },
    {
        name: 'USA',
        icon: <UsaFlagSvg />,
        value: 1
    },
    {
        name: 'USA',
        icon: <UsaFlagSvg />,
        value: 1
    }
];