import { Messaging } from 'react-cssfx-loading';
// * Icons
import {ReactComponent as AppLogo} from '../../assets/images/logo.svg';
// * Styles
import './loading-screen.styles.scss';

const LoadingScreen = () => {
    return(
        <div className="loading-screen-container">
            <div className="loading-screen-logo">
                <AppLogo className='loading-screen-logo-icon' />
            </div>
            <Messaging className='loading-screen-animation' duration='0.5s' />
        </div>
    )
}

export default LoadingScreen;