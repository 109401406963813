import FetchSettings from '../../services/setting/setting.api';
import { fetchSettingsFailed, fetchSettingsStart, fetchSettingsSuccess } from './setting.action';

// Thunk async functions should be defined right here
export const getSettings = (data) => async (dispatch) => {
    dispatch(fetchSettingsStart())
    try {
        const res = await FetchSettings(data)
        dispatch(fetchSettingsSuccess(res.data.data))
    } catch (error) {
        dispatch(fetchSettingsFailed(error))
    }
} 