export const AUTH_ACTION_TYPES = {
  SET_AUTH_TYPE: "auth/SET_AUTH_TYPE",
  SET_AUTH_FORM_TYPE_ACTIVE: "auth/SET_AUTH_FORM_TYPE_ACTIVE",
  SET_AUTH_DATA: "auth/SET_AUTH_DATA",
  CLEAR_AUTH_DATA: "auth/CLEAR_AUTH_DATA",
  CLEAR_AUTH_ERRORS: "auth/CLEAR_AUTH_ERRORS",

  // register user types
  REGISTER_USER_START: "auth/REGISTER_USER_START",
  REGISTER_USER_SUCCESS: "auth/REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILED: "auth/REGISTER_USER_FAILED",

  // login user types
  LOGIN_USER_START: 'auth/LOGIN_USER_START',
  LOGIN_USER_SUCCESS: 'auth/LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILED: 'auth/LOGIN_USER_FAILED',

  // verify
  SET_VEIFY_CODE: 'auth/SET_VEIFY_CODE',
  SET_VEIFY_TYPE: 'auth/SET_VEIFY_TYPE',
  
  VERIFY_USER_START: 'auth/VERIFY_USER_START',
  VERIFY_USER_SUCCESS: 'auth/VERIFY_USER_SUCCESS',
  VERIFY_USER_FAILED: 'auth/VERIFY_USER_FAILED',

  // forgot password
  FORGOT_PASSWORD_START : 'auth/FORGOT_PASSWORD_START',
  FORGOT_PASSWORD_FAILED : 'auth/FORGOT_PASSWORD_FAILED',
  FORGOT_PASSWORD_SUCCESS : 'auth/FORGOT_PASSWORD_SUCCESS',

  // change password
  CHANGE_PASSWORD_START : 'auth/CHANGE_PASSWORD_START',
  CHANGE_PASSWORD_FAILED : 'auth/CHANGE_PASSWORD_FAILED',
  CHANGE_PASSWORD_SUCCESS : 'auth/CHANGE_PASSWORD_SUCCESS',

  // generate key
  GENERATE_KEY_START : 'auth/GENERATE_KEY_START',
  GENERATE_KEY_SUCCESS : 'auth/GENERATE_KEY_SUCCESS',
  GENERATE_KEY_FAILED : 'auth/GENERATE_KEY_FAILED',

};
