import { AUTH_ACTION_TYPES } from "./auth.types";
import { createAction } from "../../utils/reducer/reducer.utils";

export const setAuthType = (type) =>
  createAction(AUTH_ACTION_TYPES.SET_AUTH_TYPE, type);
export const setAuthFormTypeActive = (type) =>
  createAction(AUTH_ACTION_TYPES.SET_AUTH_FORM_TYPE_ACTIVE, type);
export const setAuthData = (type) =>
  createAction(AUTH_ACTION_TYPES.SET_AUTH_DATA, type);
export const clearAuthData = (type) =>
  createAction(AUTH_ACTION_TYPES.CLEAR_AUTH_DATA, type);
export const clearAuthErrors = (type) =>
  createAction(AUTH_ACTION_TYPES.CLEAR_AUTH_ERRORS, type);
export const registerUserStart = () =>
  createAction(AUTH_ACTION_TYPES.REGISTER_USER_START);
export const registerUserSuccess = (userArray) =>
  createAction(AUTH_ACTION_TYPES.REGISTER_USER_SUCCESS, userArray);
export const registerUserFailed = (err) =>
  createAction(AUTH_ACTION_TYPES.REGISTER_USER_FAILED, err);

// login user --------------


export const loginUserStart = () =>
  createAction(AUTH_ACTION_TYPES.LOGIN_USER_START)

export const loginUserSuccess = (userArray) =>
  createAction(AUTH_ACTION_TYPES.LOGIN_USER_SUCCESS, userArray)

export const loginUserFailed = (err) =>
  createAction(AUTH_ACTION_TYPES.LOGIN_USER_FAILED, err)


// verify -----------------

export const setVerifyType = (type) =>
  createAction(AUTH_ACTION_TYPES.SET_VEIFY_TYPE, type)

export const setVerifyCode = (code) =>
  createAction(AUTH_ACTION_TYPES.SET_VEIFY_CODE, code)

export const verifyUserStart = () =>
  createAction(AUTH_ACTION_TYPES.VERIFY_USER_START)

export const verifyUserSuccess = (user) =>
  createAction(AUTH_ACTION_TYPES.VERIFY_USER_SUCCESS, user)

export const verifyUserFailed = (err) =>
  createAction(AUTH_ACTION_TYPES.VERIFY_USER_FAILED, err)


// forgot password -----------------

export const forgotPasswordStart = () =>
  createAction(AUTH_ACTION_TYPES.FORGOT_PASSWORD_START)
export const forgotPasswordSuccess = (data) =>
  createAction(AUTH_ACTION_TYPES.FORGOT_PASSWORD_SUCCESS, data)
export const forgotPasswordFailed = (err) =>
  createAction(AUTH_ACTION_TYPES.FORGOT_PASSWORD_FAILED, err)


// change password -----------------
export const changePasswordStart = () =>
  createAction(AUTH_ACTION_TYPES.CHANGE_PASSWORD_START)
export const changePasswordSuccess = (data) =>
  createAction(AUTH_ACTION_TYPES.CHANGE_PASSWORD_SUCCESS, data)
export const changePasswordFailed = (err) =>
  createAction(AUTH_ACTION_TYPES.CHANGE_PASSWORD_FAILED, err)

// generate key -----------------
export const generateKeyStart = () =>
  createAction(AUTH_ACTION_TYPES.GENERATE_KEY_START)
export const generateKeySuccess = (data) =>
  createAction(AUTH_ACTION_TYPES.GENERATE_KEY_SUCCESS, data)
export const generateKeyFailed = (err) =>
  createAction(AUTH_ACTION_TYPES.GENERATE_KEY_FAILED, err)