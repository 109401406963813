// Product Images
import Image01 from "../../assets/images/products/01.png";
import Image02 from "../../assets/images/products/02.png";
import Image03 from "../../assets/images/products/03.png";
import Image04 from "../../assets/images/products/04.png";
import Image05 from "../../assets/images/products/05.png";
import Image06 from "../../assets/images/products/06.png";
import Image07 from "../../assets/images/products/07.png";
import Image08 from "../../assets/images/products/08.png";
import Image09 from "../../assets/images/products/09.png";
// Offers Images
import BedIcon from "../../assets/images/Icons/offers/bed-icon.png";
import BathIcon from "../../assets/images/Icons/offers/bath-icon.png";
import SizeIcon from "../../assets/images/Icons/offers/size-icon.png";
import KitchenIcon from "../../assets/images/Icons/offers/kitchen-icon.png";
import CarIcon from "../../assets/images/Icons/offers/car-icon.png";
import WifiIcon from "../../assets/images/Icons/offers/wifi-icon.png";
import BalconyIcon from "../../assets/images/Icons/offers/balcony-icon.png";
import PetsIcon from "../../assets/images/Icons/offers/pets-icon.png";
import TvIcon from "../../assets/images/Icons/offers/tv-icon.png";
// Avatar Images
import UserAvatar01 from "../../assets/images/avatars/01.png";

const images = [
  Image01,
  Image02,
  Image03,
  Image04,
  Image05,
  Image06,
  Image07,
  Image08,
  Image09,
];

const currencyFormat = (number) => {
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
export const Products = Array.from({ length: 9 }, (_, i) => {
  return {
    id: i,
    title: "Palm Harbor",
    desc: "2699 Green Valley, Highland Lake,FL",
    cost: `${currencyFormat(Math.floor(Math.random() * 2500) + 1)}`,
    type: i % 2 === 0 ? "Rent" : "Buy",
    stars: 4.8,
    image: images[i],
    popular: i === 1 || i === 5 ? true : false,
    offers: [
      { icon: BedIcon, value: "2 Bed room" },
      { icon: BathIcon, value: "1 Bath" },
      { icon: SizeIcon, value: "62sq m" },
      { icon: KitchenIcon, value: "Kitchen" },
      { icon: CarIcon, value: "Parking" },
      { icon: WifiIcon, value: "Wifi" },
      { icon: BalconyIcon, value: "Balcony" },
      { icon: PetsIcon, value: "Pets allowed" },
      { icon: TvIcon, value: "TV" },
    ],
    reviews: [
      {
        avatar: UserAvatar01,
        name: "Micheal Joy Das",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        humanDate: "2h",
        rate: "4.0",
        replies: [
          {
            avatar: UserAvatar01,
            name: "Micheal Joy Das 02",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            humanDate: "2h",
            rate: "4.0",
          },
          {
            avatar: UserAvatar01,
            name: "Micheal Joy Das 03",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            humanDate: "2h",
            rate: "4.0",
          },
        ]
      },
      {
        avatar: UserAvatar01,
        name: "Micheal Joy Das",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        humanDate: "2h",
        rate: "4.0",
      },
      {
        avatar: UserAvatar01,
        name: "Micheal Joy Das",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        humanDate: "2h",
        rate: "4.0",
        replies: [
          {
            avatar: UserAvatar01,
            name: "Micheal Joy Das 03",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            humanDate: "2h",
            rate: "4.0",
          },
        ]
      },
      {
        avatar: UserAvatar01,
        name: "Micheal Joy Das",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        humanDate: "2h",
        rate: "4.0",
      },
    ],
    keywords: [
      {
        id: 1,
        title: "Beds",
        value: "3",
        icon: BedIcon,
      },
      {
        id: 2,
        title: "Bathrooms",
        value: "2",
        icon: BathIcon,
      },
      {
        id: 3,
        title: "Size",
        value: "5×7 m‌‌‌‌",
        icon: SizeIcon,
      },
    ],
  };
});
